import React from 'react';
import { Box, FormControl, MenuItem, Select, Pagination, PaginationItem } from '@mui/material';
import { useThemeMode } from '../../partials';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const CommonPagination = ({
  currentPage,
  pageSize,
  totalItems,
  totalPages,
  onPageChange,
  onPageSizeChange,
  title,
}) => {

  const { mode } = useThemeMode()
  const Theme = createTheme({
    palette: {
      mode: mode ==='dark' ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', alignItems: 'center' }} >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ mr: 1, minWidth: 60 }} size="small">
            <Select
              value={pageSize}
              onChange={onPageSizeChange}
              variant="outlined"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>
          <Box>
            Showing {(currentPage - 1) * pageSize + 1}-{Math.min(currentPage * pageSize, totalItems)} of {totalItems} {title}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={onPageChange}
            siblingCount={0}
            shape="rounded"
            showFirstButton
            showLastButton
            sx={{
              '& .Mui-selected': {
                backgroundColor: '#009ef7 !important',
                color: '#ffffff !important',
              }
            }}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                components={{ first: () => <span>First</span>, last: () => <span>Last</span> }}
              />
            )}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default CommonPagination;
